import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";

import {
  type CaseFilters,
  CaseStatus,
  type WorkerStatisticsResponse,
  workerStatisticsResponseSchema,
} from "../types";
import { HOME_HEALTH_APP_EVENTS } from "./events";

export function buildWorkerStatisticsUrl(workerId: string) {
  return `${environmentConfig.REACT_APP_WORKER_APP_BFF_URL}/home-health/workers/${workerId}/global-statistics`;
}

export function useHomeHealthStatistics(
  filters: CaseFilters,
  workerId: string,
  options: UseGetQueryOptions<WorkerStatisticsResponse> = {}
) {
  const filtersWithDefaults = {
    ...filters,
    filter: { booked: false, status: CaseStatus.OPEN, ...filters.filter },
  };

  const url = buildWorkerStatisticsUrl(workerId);

  return useGetQuery({
    url,
    queryParams: filtersWithDefaults,
    responseSchema: workerStatisticsResponseSchema,
    meta: {
      logErrorMessage: HOME_HEALTH_APP_EVENTS.GET_WORKER_STATISTICS_FAILURE,
    },
    retry: 0,
    staleTime: 1000 * 60 * 10,
    refetchInterval: 1000 * 60 * 10,
    ...options,
  });
}
