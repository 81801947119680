import { type Visit, VisitType, WorkerRequirements } from "../types";

const visitTypeOrder: VisitType[] = [
  VisitType.ADMISSION,
  VisitType.EVALUATION,
  VisitType.REGULAR,
  VisitType.RESUMPTION_OF_CARE,
  VisitType.RECERTIFICATION,
  VisitType.SUPERVISORY,
  VisitType.DISCHARGE,
];

const regularVisitTypeOrder: WorkerRequirements[] = [
  WorkerRequirements.RN,
  WorkerRequirements.LVN,
  WorkerRequirements.CNA,
  WorkerRequirements.CHHA,
  WorkerRequirements.CAREGIVER,
  WorkerRequirements.PT,
  WorkerRequirements.PTA,
];

export function sortVisitsByDateAndType(visit1: Visit, visit2: Visit) {
  const visit1TypeIndex = visitTypeOrder.indexOf(visit1.attributes.type);
  const visit2TypeIndex = visitTypeOrder.indexOf(visit2.attributes.type);
  if (visit1TypeIndex !== visit2TypeIndex) {
    return visit1TypeIndex - visit2TypeIndex;
  }

  if (
    visit1.attributes.type === VisitType.REGULAR &&
    visit2.attributes.type === VisitType.REGULAR
  ) {
    const regularVisit1TypeIndex = regularVisitTypeOrder.indexOf(
      visit1.attributes.workerReq as WorkerRequirements
    );
    const regularVisit2TypeIndex = regularVisitTypeOrder.indexOf(
      visit2.attributes.workerReq as WorkerRequirements
    );
    return regularVisit1TypeIndex - regularVisit2TypeIndex;
  }

  return 0;
}
