import { Card, CardContent, CardHeader } from "@mui/material";

import { type Case } from "../types";
import { HomeHealthCaseHeader } from "./CaseHeader";

interface Props {
  caseItem: Case;
  content?: JSX.Element;
}

export function HomeHealthCaseCard(props: Props) {
  const { caseItem, content } = props;

  return (
    <Card
      variant="elevation"
      elevation={6}
      sx={{ backgroundColor: "white", position: "relative", width: "100%" }}
    >
      <CardHeader
        title={caseItem.attributes.patient.attributes.externalPatientIdentifier}
        sx={{ paddingBottom: 0 }}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        <HomeHealthCaseHeader caseItem={caseItem} />
        {content}
      </CardContent>
    </Card>
  );
}
