import { InternalLink, Text } from "@clipboard-health/ui-react";
import { Chip, Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";

import { type Case } from "../types";
import { GoogleAddressLink } from "./GoogleAddressLink";

interface Props {
  caseItem: Case;
}

export function HomeHealthCaseHeader(props: Props) {
  const { caseItem } = props;

  return (
    <Stack spacing={1} alignItems="flex-start">
      <Stack direction="row" spacing={1} flexWrap="wrap">
        <InternalLink
          aria-label={`Show facility details for ${caseItem.attributes.workplace.attributes.name}`}
          to={`${RootPaths.HOME}/facility/${caseItem.attributes.workplace.attributes.userId}`}
          fontSize="medium"
        >
          {caseItem.attributes.workplace.attributes.name}
        </InternalLink>
      </Stack>
      {(caseItem.attributes.specialties.length > 0 ||
        caseItem.attributes.patient.attributes.oasis) && (
        <Stack direction="row" spacing={1} flexWrap="wrap">
          {caseItem.attributes.patient.attributes.oasis && (
            <Chip label="OASIS" size="small" variant="outlined" />
          )}
          {caseItem.attributes.specialties.map((specialty) => (
            <Chip key={specialty} label={specialty} size="small" variant="outlined" />
          ))}
        </Stack>
      )}
      <GoogleAddressLink
        obscured
        formattedAddress={caseItem.attributes.patient.attributes.formattedAddress}
      />
      <Text variant="subtitle1" color={(theme) => theme.palette.grey[700]}>
        {caseItem.attributes.description}
      </Text>
    </Stack>
  );
}
