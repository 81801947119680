import { format } from "date-fns";

export function formatHourWithAMPM(date: Date) {
  return format(date, "h a");
}

export function formatDateWithFullMonth(date: Date) {
  // format date in April 20, 2023
  return format(date, "MMMM d, yyyy");
}
