import { formatShortDateWithWeekday } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Chip, Divider, Stack } from "@mui/material";
import { startCase, toLower } from "lodash";

import { PricingType, type Visit, VisitType } from "../types";

interface Props {
  visit: Visit;
  instantPay?: boolean;
}

export function HomeHealthCaseVisitDetails(props: Props) {
  const { visit, instantPay } = props;

  return (
    <Stack width="100%" alignItems="flex-start">
      <Text bold>
        {startCase(toLower(visit.attributes.type))}{" "}
        {[VisitType.REGULAR].includes(visit.attributes.type) && ` Visits`}
        {[
          VisitType.REGULAR,
          VisitType.ADMISSION,
          VisitType.EVALUATION,
          VisitType.DISCHARGE,
        ].includes(visit.attributes.type) && ` - ${visit.attributes.workerReq!}`}
      </Text>
      {visit.attributes.type === VisitType.REGULAR &&
        visit.attributes.pricingType === PricingType.PER_HOUR && (
          <Chip size="small" label="Hourly" color="info" />
        )}
      {[VisitType.ADMISSION, VisitType.RESUMPTION_OF_CARE].includes(visit.attributes.type) && (
        <Text variant="subtitle2">
          Deadline: {formatShortDateWithWeekday(visit.attributes.deadline!)}
        </Text>
      )}
      {visit.attributes.type === VisitType.REGULAR && (
        <Text variant="subtitle2">
          {visit.attributes.visitsPerWeek!} per week, {visit.attributes.durationInWeeks!}
          {visit.attributes.durationInWeeks! > 1 ? " weeks total" : " week"}
        </Text>
      )}
      {visit.attributes.type === VisitType.REGULAR &&
        visit.attributes.pricingType === PricingType.PER_HOUR && (
          <>
            {isDefined(visit.attributes.estimatedStartTime) && (
              <Text variant="subtitle2">Starts at {visit.attributes.estimatedStartTime}</Text>
            )}
            <Text variant="subtitle2">Duration {visit.attributes.estimatedDuration!} hours</Text>
          </>
        )}
      <Stack direction="row" spacing={1}>
        <Text bold color="primary">
          ${(visit.attributes.payRate?.amountInMinorUnits ?? 0) / 100}{" "}
          {visit.attributes.type === VisitType.REGULAR &&
            (visit.attributes.pricingType === PricingType.PER_VISIT ? " per visit" : " per hour")}
        </Text>
        {instantPay && (
          <>
            <Divider flexItem orientation="vertical" sx={{ borderRightWidth: "medium" }} />
            <Text bold color={(theme) => theme.palette.success.light}>
              InstantPay - Trusted
            </Text>
          </>
        )}
      </Stack>
    </Stack>
  );
}
