import { ExternalLink } from "@clipboard-health/ui-react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Stack } from "@mui/material";

import { obscureAddress } from "./address";

interface Props {
  formattedAddress: string;
  obscured?: boolean;
}

export function GoogleAddressLink(props: Props) {
  const { formattedAddress, obscured } = props;

  return (
    <ExternalLink to={`https://maps.google.com/maps?q=${formattedAddress}`}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <Box>{obscured ? obscureAddress(formattedAddress) : formattedAddress}</Box>
        <OpenInNewIcon fontSize="inherit" />
      </Stack>
    </ExternalLink>
  );
}
